





































































































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import {
  GetShuxinglaiyuan,
  AddShuxinglaiyuan,
  DeleteShuxinglaiyuan,
  EditShuxinglaiyuan,
} from "@/request/check";
import MainHeader from "@/components/main-header/main-header.vue";
@Component({
  components: {
    MainHeader,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  private data: any[] = [];
  private editData: any = {};
  private ifShowEdit: any = false;
  private type: any = "";
  private addOne() {
    this.type = "新增";
    this.editData = {};
    this.ifShowEdit = true;
  }
  private addSure() {
    if (!this.editData["来源名称"]) {
      this.$message.warning("请输入来源名称！");
      return;
    }
    const params: any = {
      来源名称: this.editData["来源名称"],
      证据等级: this.editData["证据等级"],
      推荐类别: this.editData["推荐类别"],
    };
    if (this.type === "新增") {
      AddShuxinglaiyuan(this, params).then((res: any) => {
        this.$message.success("创建成功");
        this.ifShowEdit = false;
        this.getList();
      });
    } else {
      params.id = this.editData.id;
      EditShuxinglaiyuan(this, params).then((res: any) => {
        this.$message.success("编辑成功");
        this.ifShowEdit = false;
        this.getList();
      });
    }
  }
  /**
   * @description 跳转用户编辑
   */
  private jumpCompose(item: any) {
    if (!this.getIsShowQuanxian("属性来源编辑")) {
      return;
    }
    this.type = "编辑";
    this.editData = JSON.parse(JSON.stringify(item));
    this.ifShowEdit = true;
  }
  private deleteOne(item: any) {
    if (!this.getIsShowQuanxian("属性来源删除")) {
      return;
    }
    this.$confirm("您是否确定删除？", "删除", {
      customClass: "commonConfirm",
    })
      .then(() => {
        const params: any = {
          data: {
            id: item.id,
          },
        };
        DeleteShuxinglaiyuan(this, params).then((res: any) => {
          this.$message.success("删除成功");
          this.getList();
        });
      })
      .catch(() => {
        console.log("取消");
      });
  }
  /**
   * @description 获取用户列表
   */
  getList() {
    const params: any = {
      params: {
        search: this.filters.search,
        order_field: this.filters.sort_field,
        order_value: this.filters.sort_value,
        current_page: this.filters.current_page,
        page_num: this.filters.page_count,
      },
    };
    localStorage.setItem("shuxinglaiyuanFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetShuxinglaiyuan(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.data = data.data;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 初始化
   */
  mounted() {
    this.filters.sort_field = "更新时间";
    const d = localStorage.getItem("shuxinglaiyuanFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.getList();
  }
}
